import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_accounts';

const contactRoute = [
    {
        path: '/contact',
        name: 'Contacts',
        exact: true,
        component: () => import('@/pages/contact/Index'),
        meta: {
            auth: true,
            permission: ['contact.access', 'portal.contact.account.access'],
        },
    },
];

export default applyPageGroupKeyToParents(contactRoute, { pageGroupKey });
