import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_sp_service_page';

const serviceRoute = [
    {
        path: '/services',
        // name: 'portal.services.access',
        name: 'services.access',
        exact: true,
        component: () => import('@/pages/services/Main.vue'),
        meta: {
            auth: true,
            permission: ['services.access', 'portal.services.access'],
            title : 'Services List'
        },
    },
];

export default applyPageGroupKeyToParents(serviceRoute, { pageGroupKey });
