import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_event';

const eventRoute = [
    {
        path: '/event',
        name: 'Event Listing',
        exact: true,
        component: () => import('@/pages/event/Index'),
        meta: {
            auth: true,
            permission: ['event.access'],
        },
    },
    {
        path: '/event-template-field',
        name: 'Event Template Fields',
        exact: true,
        component: () => import('@/pages/event/event_template_field/Index'),
        meta: {
            auth: true,
            permission: ['event.template-field.access'],
        },
    },
    {
        path: '/event-type-configuration',
        name: 'Event Type Configuration',
        exact: true,
        component: () => import('@/pages/event/event_type_configuration/Index'),
        meta: {
            auth: true,
            permission: ['event.event-type-configuration.access'],
        },
    },
];

export default applyPageGroupKeyToParents(eventRoute, { pageGroupKey });
