import { defineStore } from 'pinia';
import { parseDate } from '@/utilities/dateUtils';

/**
 * Use if there are multiple data tables on one page
 * storeID MUST be different each datatable
 * @param storeID
 * @returns {string|string|*|StoreDefinition<string, {appliedFilters: boolean, apiUrl: string, filters: {}, error: boolean, mappedFilters: []}, {hasFilters(*): boolean, computedApiUrl(*): (string)}, {applyFilters(*): (boolean|undefined), clearFilters(): void}>|boolean|string}
 */
export function createDataTableStore(storeID) {
    return defineStore('search-filters-'+storeID, {
        state: () => {
            return {
                filters: {},
                mappedFilters: [],
                apiUrl: '',
                error: false,
                appliedFilters: false, // Track whether filters have been applied
                showDeleted: false,
                showDetails: false,
                showOriginalInvoiceId: false,
                showInvoiceId: false,
                showPurchaseOrderNo: false,
                showZeroInvoices: false,
                showDueOnly: false,
                oldestFirst: false,
                internalOnly: false,
                forceRefresh: null, // Null or timestamp
                showComplex: false,
                hideDataTableResult: false, // for toggling onload data table without result
                allowSearchWithoutCustomSearch: false
            };
        },
        getters: {
            /**
             * This is necessary to check if the user updates the filter(s).
             */
            computedApiUrl(state) {
                return getComputedApiUrl(state);
            },
            /**
             * Check if any filters has been applied.
             */
            hasFilters(state) {
                return Object.values(state.filters).some(value => value !== '');
            },
        },
        actions: {
            applyFilters(data) {
                /**
                 * Apply filters and format the request payload accordingly.
                 * ex.
                 * {
                 *     column: <field-from-BE>,
                 *     value: <value-to-pass>',
                 *     type: int | string | date | single-date
                 * }
                 */
                this.setHideDataTableResult(false)
                this.mappedFilters = data.map(filter => {
                    const
                        key = Object.keys(filter)[0],
                        { type, format } = Object.values(filter)[0];

                    let value = this.filters[key];

                    // Parse data when format is "MM YY"
                    if (format === 'M yy' && value !== null ) {
                        const parsedDate = parseDate(value)
                        if (parsedDate !== null) {
                            value = parsedDate
                        }
                    }

                    return { column: key, value, type };
                }).filter(item => item.value !== undefined && item.value !== null && item.value);

                if (this.allowSearchWithoutCustomSearch == false) {
                    // Display error if no filters are applied.
                    if (this.mappedFilters.length < 1 && !this.apiUrl.includes('?customSearch=')) {
                        this.error = true;
                        return false;
                    }
                }

                // If "Clear Filters" has been triggered, re-render and display the default apiUrl
                if (this.mappedFilters.length < 1 && !this.appliedFilters) {
                    this.apiUrl = this.apiUrl.split('?customSearch=')[0];
                    return false;
                }

                // Update appliedFilters state
                this.appliedFilters = true;

                if (this.mappedFilters.length > 0 && !this.apiUrl.includes('?customSearch=')) {
                    this.apiUrl += '?customSearch=' + JSON.stringify(this.mappedFilters);
                    this.error = false;
                }
            },
            clearFilters() {
                this.filters = {};
                this.mappedFilters = [];
                this.error = false;

                // Reset states
                this.appliedFilters = false;
                this.showDetails = false;
            },
            setShowDeleted(value) {
                this.showDeleted = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowDetails(value) {
                this.showDetails = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowOriginalInvoiceId(value) {
                this.showOriginalInvoiceId = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowInvoiceId(value) {
                this.showInvoiceId = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowPurchaseOrderNo(value) {
                this.showPurchaseOrderNo = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowZeroInvoices(value) {
                this.showZeroInvoices = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowDueOnly(value) {
                this.showDueOnly = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setOldestFirst(value) {
                this.oldestFirst = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setInternalOnly(value) {
                this.internalOnly = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setForceRefresh(value) {
                this.forceRefresh = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setShowComplex(value) {
                this.showComplex = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setHideDataTableResult(value) {
                this.hideDataTableResult = value;
                this.apiUrl = getComputedApiUrl(this.$state);
            },
            setAllowSearchWithoutCustomSearch(value) {
                this.allowSearchWithoutCustomSearch = value;
            },
            setInvoiceIdColumnSettingByValue(value) {
                /**
                 * 0 - Show both
                 * 1 - Show Invoice ID Only
                 * 2 - Show Original Invoice Id
                 */
                this.setShowInvoiceId([1,0].includes(value));
                this.setShowOriginalInvoiceId([2,0].includes(value));
            }
        },
    });
}

export const useFilterDataTableStore = defineStore('search-filters', {
    state: () => {
        return {
            filters: {},
            mappedFilters: [],
            apiUrl: '',
            error: false,
            appliedFilters: false, // Track whether filters have been applied
            showDeleted: false,
            showDetails: false,
            showOriginalInvoiceId: false,
            showInvoiceId: false,
            showPurchaseOrderNo: false,
            showZeroInvoices: false,
            showDueOnly: false,
            oldestFirst: false,
            internalOnly: false,
            forceRefresh: null, // Null or timestamp
            showComplex: false,
            hideDataTableResult: false, // for toggling onload data table without result
            allowSearchWithoutCustomSearch: false
        };
    },
    getters: {
        /**
         * This is necessary to check if the user updates the filter(s).
         */
        computedApiUrl(state) {
            return getComputedApiUrl(state);
        },
        /**
         * Check if any filters has been applied.
         */
        hasFilters(state) {
            return Object.values(state.filters).some(value => value !== '');
        },
    },
    actions: {
        applyFilters(data) {
            /**
             * Apply filters and format the request payload accordingly.
             * ex.
             * {
             *     column: <field-from-BE>,
             *     value: <value-to-pass>',
             *     type: int | string | date | single-date
             * }
             */
            this.setHideDataTableResult(false)
            this.mappedFilters = data.map(filter => {
                const
                    key = Object.keys(filter)[0],
                    { type } = Object.values(filter)[0],
                    value = this.filters[key];

                return { column: key, value, type };
            }).filter(item => item.value !== undefined && item.value !== null && item.value);

            if (this.allowSearchWithoutCustomSearch == false) {
                // Display error if no filters are applied.
                if (this.mappedFilters.length < 1 && !this.apiUrl.includes('?customSearch=')) {
                    this.error = true;
                    return false;
                }
            }

            // If "Clear Filters" has been triggered, re-render and display the default apiUrl
            if (this.mappedFilters.length < 1 && !this.appliedFilters) {
                this.apiUrl = this.apiUrl.split('?customSearch=')[0];
                return false;
            }

            // Update appliedFilters state
            this.appliedFilters = true;

            if (this.mappedFilters.length > 0 && !this.apiUrl.includes('?customSearch=')) {
                this.apiUrl += '?customSearch=' + JSON.stringify(this.mappedFilters);
                this.error = false;
            }
        },
        clearFilters() {
            this.filters = {};
            this.mappedFilters = [];
            this.error = false;

            // Reset states'
            this.appliedFilters = false;
            this.showDetails = false;
        },
        setShowDeleted(value) {
            this.showDeleted = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowDetails(value) {
            this.showDetails = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowOriginalInvoiceId(value) {
            this.showOriginalInvoiceId = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowInvoiceId(value) {
            this.showInvoiceId = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowPurchaseOrderNo(value) {
            this.showPurchaseOrderNo = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowZeroInvoices(value) {
            this.showZeroInvoices = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowDueOnly(value) {
            this.showDueOnly = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setOldestFirst(value) {
            this.oldestFirst = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setInternalOnly(value) {
            this.internalOnly = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setForceRefresh(value) {
            this.forceRefresh = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setShowComplex(value) {
            this.showComplex = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setHideDataTableResult(value) {
            this.hideDataTableResult = value;
            this.apiUrl = getComputedApiUrl(this.$state);
        },
        setAllowSearchWithoutCustomSearch(value) {
            this.allowSearchWithoutCustomSearch = value;
        }
    },
});

// Helper function to compute the apiUrl
function getComputedApiUrl(state) {
    let baseUrl;
    const hasCustomSearch = state.apiUrl.includes('?customSearch=');
    const customSearchQuery = `customSearch=${JSON.stringify(state.mappedFilters)}`;

    if (state.mappedFilters.length > 0) {
        baseUrl = hasCustomSearch
            ? state.apiUrl.split('?customSearch=')[0]
            : state.apiUrl;

        baseUrl += baseUrl.includes('?')
            ? `&${customSearchQuery}`
            : `?${customSearchQuery}`;
    } else {
        baseUrl = state.apiUrl;
    }

    // @todo: This can still be refactored to make it more dynamic.
    // @todo: other unnecessary query string can be eliminated probably thru route checking
    const queryParams = new URLSearchParams(baseUrl.includes('?') ? baseUrl.split('?')[1] : '');
    queryParams.set('show_deleted', state.showDeleted);
    queryParams.set('show_details', state.showDetails);
    queryParams.set('show_due_only', state.showDueOnly);
    queryParams.set('oldest_first', state.oldestFirst);
    queryParams.set('internal_only', state.internalOnly);
    queryParams.set('force_refresh', state.forceRefresh);
    queryParams.set('show_complex', state.showComplex);
    queryParams.set('show_original_invoice_id', state.showOriginalInvoiceId);
    queryParams.set('show_invoice_id', state.showInvoiceId);
    queryParams.set('show_purchase_order_no', state.showPurchaseOrderNo);
    queryParams.set('hide_data_table_result', state.hideDataTableResult);
    queryParams.set('show_zero_invoices', state.showZeroInvoices)

    return `${baseUrl.split('?')[0]}?${queryParams.toString()}`;
}