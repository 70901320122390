<template>
    <div class='grid'>
        <div class='col-12'>
            <p :class='is_from_login ? "h-4rem": "pb-2"' v-if='is_from_login'>
                {{ t('two_fa_required_login_heading', 'As an added layer of security, multifactor authentication has been added to your account. ')
                }}
            </p>
            <p :class='is_from_login ? "h-4rem": "mt-3 mb-2"'>
                {{ t('two_fa_activate_heading', 'To activate, download an Authenticator app from your mobile device and then scan the QR code.')
                }}
            </p>
            <div class='mb-4' v-html='qr_code.svg'></div>

            <p class='mb-1'>{{ twofa_code_heading }}</p>

            <div class='col-6 p-0 mb-4'>
                <NimbusInputText :placeholder="t('two_fa_verify_code_title', 'Enter the code to verify')"
                                 v-model='twofa_code' required :errorMessage='errors.code' @keyup.enter='enroll2FA'></NimbusInputText>
            </div>

            <Button class='' @click='enroll2FA' :label="t('two_fa_verify_code_button', 'Verify Code')" />
        </div>

        <div class='col-12 mt-3' v-if='is_from_login'>
            <a href='https://www.acma.gov.au/customer-identity-authentication-rules' target='_blank'>
                {{ t('two_fa_btn_acma', 'Why do we need this?') }}
            </a>
        </div>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import t from '@/service/Translate';
import { enable2FA, twoFactorQRCode } from '@/service/TwoFactorAuthService';
import { ref } from 'vue';
import NimbusInputText from '@/components/nimbus/NimbusInputText';
import { toRefs } from 'vue';
import { useConfirm } from 'primevue/useconfirm';

export default {
    name: 'TwoFAQRCode',
    components: { NimbusInputText },
    props: {
        twofa_code_heading: {
            type: String,
        },
        is_from_login: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['twoFAEvents'],
    setup(props, { emit }) {
        const confirm = useConfirm()
        const twofa_code = ref('');
        const loading = ref(false);
        const qr_code = ref([]);
        const errors = ref([]);
        const { is_from_login } = toRefs(props);

        const loadTwoFactorQRCode = async () => {
            qr_code.value = await twoFactorQRCode();
        };

        const enroll2FA = async () => {
            try {
                loading.value = true;
                let params = {
                    secret_2fa: qr_code.value.generated_secret_key,
                    code: twofa_code.value,
                };

                await enable2FA(params);
                if (is_from_login.value) {
                    confirm.require({
                        message: t('two_fa_dlg_enrolled_message','A verification process will be done using an Authenticator App every time you log in to your account.'),
                        header: t('two_fa_dlg_enrolled_title', 'You are now enrolled to Multifactor Authentication.'),
                        icon: 'pi pi-info-circle',
                        acceptClass: 'p-button p-component p-button-outlined p-button-info',
                        acceptLabel: t('two_fa_btn_go_to_account', 'Go to my account'),
                        rejectClass: 'hidden',
                        accept: () => {
                            emit('twoFAEvents', true);
                        },
                    });
                } else {
                    emit('twoFAEvents', true);
                }
            } catch (error) {
                if (error.response.status == 401) {
                    emit('twoFAEvents', false);
                } else {
                    loading.value = false;
                    errors.value = error.response.data;
                }
            }
        };

        loadTwoFactorQRCode();

        return {
            qr_code,
            twofa_code,
            errors,
            t,
            enroll2FA,
        };
    },
};
</script>

<style scoped></style>
