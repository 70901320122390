import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const
    page = 'account_receivables',
    pageGroupKey = 'page_group_finance';

const accountReceivableRoute = [
    {
        path: '/finance/' + page,
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/finance/account_receivables/Index'),
        meta: {
            auth: true,
            permission: ['finance.account-receivables.access'],
            title: 'Account Receivables',
            prefix: `${page}-main`
        },
        children: [
            {
                path: `/finance/${page}/invoice`,
                name: `${page}-invoice`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/invoice/InvoiceMain'),
                meta: {
                    auth: true,
                    permission: ['finance.account-receivables.invoice'],
                    title: 'Invoices',
                    prefix: `${page}-invoice`,
                },
            },
            {
                path: `/finance/${page}/transaction`,
                name: `${page}-transaction`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/transactions/Main'),
                meta: {
                    auth: true,
                    permission: ['finance.account-receivables.transaction'],
                    title: 'Transactions',
                    prefix: `${page}-transaction`,
                },
            },
            {
                path: `/finance/${page}/profit-center`,
                name: `${page}-profit-center`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/profit_centre/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.access'],
                    title: 'Profit Centers',
                    prefix: `${page}-profit-center`,
                }
            },
            {
                path: `/finance/${page}/profit-center/create`,
                name: `${page}-profit-center-create`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/profit_centre/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.create'],
                    title: 'Create Profit Center',
                    prefix: `${page}-profit-center`,
                }
            },
            {
                path:  `/finance/${page}/profit-center/:id`,
                name: `${page}-profit-center-view`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/profit_centre/View.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.view'],
                    title: 'View Profit Center',
                    prefix: `${page}-profit-center-view`,
                }
            },
            {
                path:  `/finance/${page}/profit-center/:id/edit`,
                name: `${page}-profit-center-edit`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/profit_centre/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.edit'],
                    title: 'View Profit Center',
                    prefix: `${page}-profit-center-edit`,
                }
            },
        ]
    },
];

export default applyPageGroupKeyToParents(accountReceivableRoute, { pageGroupKey });
