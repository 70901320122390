export const DIALOG_CONFIRMATION = {
    paymentConfirmation: {
        icon: 'pi-shopping-bag',
        summary: `How do you want to pay for your invoice?`,
    },
    chooseExistingPaymentMethod: {
        icon: 'pi-credit-card',
        summary: 'Choose from your existing payment method',
    },
    paymentFailed: {
        icon: 'pi-exclamation-circle',
        iconSeverity: 'danger',
        summary: 'Payment failed',
        detail: 'Your payment was not received.',
        btn: {
            label: 'Try again',
            isLoading: false,
        }
    },
    paymentSuccess: {
        icon: 'pi-thumbs-up',
        iconSeverity: 'success',
        summary: 'Payment complete',
    },
    paymentProcessing: {
        icon: 'pi-hourglass',
        iconSeverity: 'blue-500',
        summary: 'Processing',
    },
};


export const INVOICE_TYPE = {
    INVOICE: 'invoice',
    ORIGINAL_INVOICE: 'original_invoice'
}

export const INVOICE_COLUMN_DATATABLE_SETTING_KEY = 'nimbus.datatatable-invoiceid-column-setting';