<script setup>
import { computed } from 'vue';
import { getColor } from '@/service/NimbusUtilities';
import { Constants } from '@/service/Constants';
import { useEUPStore } from '@/stores/eup-store';
import { storeToRefs } from 'pinia/dist/pinia';

// eslint-disable-next-line no-undef
const props = defineProps({
    label: String,
})

const
    eupStore = useEUPStore(),
    { eupSettings } = storeToRefs(eupStore);

const styleComputed = computed(() => ({
    'background-color': getColor(eupSettings.value?.main_color) ? `#${eupSettings.value?.main_color}` : Constants.DEFAULT_MAIN_COLOR,
}));
</script>

<template>
    <span
        v-if="props.label"
        class="nb-layout-sidebar__menu-tooltip"
        :style="styleComputed"
    >{{ props.label }}</span>
</template>

<style lang="scss" scoped>
@import '@/assets/abstracts/_colors.scss';

span {
    display: inline-block;
    padding: 0.75rem 1rem;
    position: relative;
    width: max-content;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    /* Ensures that only the background gets affected and not the text */
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: inherit;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        box-shadow:
            0 0 4px 0 #FFFFFF40 inset,
            0 4px 5px 0 #00000024;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
        border-right: 8px solid $sidebarActiveBGColor;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
}
</style>