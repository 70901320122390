import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_sp_reports_page';

const reportRoute = [
    {
        path: '/reports',
        name: 'reports.access',
        exact: true,
        component: () => import('@/pages/reports/Main.vue'),
        meta: {
            auth: true,
            permission: ['reports.access','portal.reports.access'],
        },
    },
    {
        path: '/reports/download',
        name: 'reports.download',
        exact: true,
        component: () => import('@/pages/reports/Main.vue'),
        meta: {
            auth: true,
            permission: ['reports.access','portal.reports.access'],
        },
    },
];

export default applyPageGroupKeyToParents(reportRoute, { pageGroupKey });
