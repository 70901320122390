import moment from 'moment';

// set the default date format
const nimbusDefaultDateFormat = 'D MMM YYYY';
export const nimbusDefaultDateTimeFormat = `${nimbusDefaultDateFormat} HH:mm`;
export const nimbusDefaultDateTimeFormatWithSeconds = `${nimbusDefaultDateFormat} HH:mm:ss`;

// default calendar date format in the database
const nimbusCalendarDateFormatString = 'DD-MM-YYYY';


/**
 * Calendar Prime vue component on UI
 */
export const calendarDateFormat = "d M yy"; // same as nimbusDefaultDateFormat = 'D MMM YYYY';


/**
 * function to return the default nimbus format for calendar
 * NOTE: this format is based in moment() formatting
 * Reference: https://momentjs.com/docs/#/displaying/
 *
 * @param showTime
 * @param hourFormat
 * @param showSeconds
 *
 * @returns {string}
 */
export function nimbusCalendarDateFormat(showTime = false, hourFormat = '12', showSeconds = false) {
    let format = nimbusDefaultDateFormat;

    if (showTime && showSeconds) {
        format += hourFormat === '24' ? ' HH:mm:ss' : ` h:mm:ss A`;
    } else if (showTime) {
        format += hourFormat === '24' ? ' HH:mm' : ` h:mm A`;
    }

    return format;
}

export default class DateService {
    getCreatedDateFormat(date, time = ' h:mm A', showTime = true) {
        let dt = moment(String(date)).format(nimbusDefaultDateFormat + time);
        if (!showTime) {
            dt = moment(String(date)).format(nimbusDefaultDateFormat);
        }
        return dt === 'Invalid date' ? 'N/A' : dt;
    }

    getModifiedDateFormat(dateTime) {
        return moment.utc(String(dateTime)).format(`${nimbusDefaultDateFormat} h:mm A`);
    }

    getAccountStartDate(dateTime) {
        return moment.utc(String(dateTime)).format('MMMM D, yyyy');
    }

    getUserLastLogin(dateTime) {
        let dt = moment.utc(String(dateTime)).format(`${nimbusDefaultDateFormat} HH:mm:ss`);
        return dt === 'Invalid date' ? 'N/A' : dt;
    }

    parseAddDate(date, factor, dateField) {
        return moment.utc(date).add(factor, dateField);
    }

    parseSubDate(date, factor, dateField) {
        return moment.utc(date).subtract(factor, dateField);
    }

    convertDateUTC(date) {
        return moment.utc(date);
    }

    parseSubIssueDateFrom(date, factor) {
        return moment(date).subtract(factor, 'days');
    }

    parseAddIssueDateTo(date, factor) {
        return moment(date).add(factor, 'days');
    }
}

/**
 * Function to calculate hours
 *
 * @param value
 * @returns {number}
 */
export function calculateHours(value) {
    return Math.floor((value % (60 * 60 * 24)) / (60 * 60));
}

/**
 * Function to calculate minutes
 *
 * @param value
 * @returns {number}
 */
export function calculateMinutes(value) {
    return Math.floor((value % (60 * 60)) / 60);
}

/**
 * Function to calculate seconds
 *
 * @param value
 * @returns {number}
 */
export function calculateSeconds(value) {
    return Math.floor(value % 60);
}

/**
 * Format timer
 *
 * @param hours
 * @param minutes
 * @param seconds
 * @returns {string}
 */
export function formattedTimer(hours, minutes, seconds) {
    let dHours = hours < 10 ? `0${hours}` : hours;
    let dMinutes = minutes < 10 ? `0${minutes}` : minutes;
    let dSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${dHours}:${dMinutes}:${dSeconds}`;
}

/**
 *
 * @param date
 * @returns {string}
 */
export function dateTimeStringUTC(date) {
    return moment(String(date.toString())).utc().format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Convert DateTime to Plain Format
 * NOTE: found an issue in axios that it's automatically converting the date to what timezone you're using
 *
 * @param date
 * @returns {string}
 */
export function fixedDateTimeFormat(date) {
    return moment(date).format();
}

/**
 * Added conversion for datetime based on timezone
 *
 * @param date
 * @param timezone
 * @param show_time
 * @returns {string}
 */
export function convertDateTimeDisplay(date, timezone = null, show_time = true) {
    let _default = moment(date);
    if (timezone) {
        _default = moment.tz(date, timezone);
    }

    return _default.format(show_time ? `${nimbusDefaultDateFormat} HH:MM` : `${nimbusDefaultDateFormat}`);
}

/**
 * Convert date into simple date format, common usage: format for Calendar component
 * @returns {string}
 * @param dateString
 * @param {string} dateFormat
 */
export function getSimpleDateFormat(dateString, dateFormat = nimbusCalendarDateFormatString) {
    return moment(dateString).format(dateFormat).toString();
}

/**
 * Check given dateString is valid
 * @returns {boolean}
 * @param dateString
 */
export function dateIsValid(dateString) {
    return moment(dateString, nimbusCalendarDateFormatString).isValid();
}

/**
 * Check given dateString to another date format
 * @returns {string}
 * @param dateString
 * @param fromFormat {string}
 * @param toFormat {string}
 */
export function convertDateString(dateString, fromFormat = nimbusCalendarDateFormatString, toFormat = nimbusDefaultDateFormat) {
    return moment.utc(dateString, fromFormat).format(toFormat).toString();
}

/**
 * Get the Dates Days Diff for two dates
 *
 * @param start
 * @param end
 * @returns {number}
 */
export function getDatesDaysDiff(start, end) {
    return Math.abs(moment(start, nimbusCalendarDateFormatString).startOf('day').diff(moment(end, nimbusCalendarDateFormatString).startOf('day'), 'days')) + 1;
}

/**
 * Convert date from specified format
 *
 * @param date
 * @param format
 * @returns {string}
 */
export function convertDateFromFormat(date, format = nimbusDefaultDateTimeFormat) {
    return moment(date).format(format).toString();
}

export function convertDateFormatMonthAndYear(dateString) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [month, year] = dateString.split('/');
    const date = new Date(year, month - 1);
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}
