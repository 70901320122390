export default [
    {
        path: '/profit-centre',
        name: 'Profit Centre',
        exact: true,
        component: () => import('@/pages/profit_centre/Index.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.access']
        }
    },
    {
        path: '/profit-centre/create',
        name: 'Profit Centre Create',
        exact: true,
        component: () => import('@/pages/profit_centre/Create.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.create']
        }
    },
    {
        path: '/profit-centre/:id',
        name: 'Profit Centre View',
        exact: true,
        component: () => import('@/pages/profit_centre/View.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.view']
        }
    },
    {
        path: '/profit-centre/:id/edit',
        name: 'Profit Centre Edit',
        exact: true,
        component: () => import('@/pages/profit_centre/Edit.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.edit']
        }
    },
]