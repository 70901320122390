import { useUserStore } from '@/stores/user-store';
import UserAuthenticationService from '@/service/UserAuthenticationService';

// placeholder only temporary redirect to ticket
export default [
    {
        path: '/',
        beforeEnter: (to, from, next) => {
            const userStore = useUserStore();
            const userAuthenticationService = new UserAuthenticationService();

            if (userAuthenticationService.isAuthenticated()) {
                if (userAuthenticationService.hasPermission('account.access')) {
                    next({ name: `accounts` });
                } else if (userAuthenticationService.hasPermission('portal.invoices.access')) {
                    next({ name: `invoice-main`, params: { accountId: userStore.userData.accountId } });
                } else {
                    next({ name: `user.profile` });
                }
            } else {
                next({ name: `login` });
            }
        },
    },
];
