import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import UserAuthenticationService from '@/service/UserAuthenticationService';
import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia/dist/pinia';

export const useAuthStore = defineStore(
    'auth',
    () => {
        const authService = new UserAuthenticationService();
        const
            userStore = useUserStore(),
            { isEup } = storeToRefs(userStore);

        /**
         * States
         */
        /** auth details **/
        const details = ref();
        const accessToken = ref(authService.getToken() || '');

        /**
         * Getters
         */
        const isAuthenticated = computed(() => !!accessToken.value);
        const headerConfig = computed(() => ({
            headers: {
                Authorization: `Bearer ${accessToken.value}`,
            },
        }));

        /**
         * Actions
         */
        async function loadDetails() {
            details.value = await authService.getAuthenticatedUser();
        }

        function clearDetails() {
            details.value = {};
        }

        /**
         * Realm will be concatenated to the username for SPP, otherwise just plain username.
         *
         * @returns {Promise<*|string>}
         */
        async function handleDisplayedUsername() {
            if (!details.value || Object.keys(details.value).length < 1) {
                await loadDetails();
            }

            /** For EUP, remove the last occurrence of "@" which assumed to be as the realm */
            const username = details.value.username;
            if (isEup.value) {
                return username.slice(0, username.lastIndexOf('@'));
            }

            return details.value.username;
        }

        return {
            /**
             * States
             */
            details,

            /**
             * Getters
             */
            headerConfig,

            /**
             * Actions
             */
            loadDetails,
            clearDetails,
            handleDisplayedUsername,
        };
    },
);