<template>
    <NimbusInputWrapper
        v-bind="$props" ref="wrapper" @right-icon-click="e => $emit('rightIconClick', e)"
        class="component-nb-input-text"
    >
        <template #input>
            <!-- v-tooltip directive throws error when given a null value -->
            <InputText
                v-if="tooltip" :disabled="disabled" v-tooltip="tooltip" v-bind="$attrs"
                :class="[wrapper?.inputBorderColorClass, inputClass]" @input="updateInput" :modelValue="modelValue"
                class="w-full niw-dynamic-border"
            />

            <!-- Note: ideally should use the new component built by PrimeVue https://v3.primevue.org/iconfield/ -->
            <!-- @todo: update primevue version -->
            <InputGroup v-else-if="$slots['append-icon']">
                <InputText
                    :modelValue="modelValue"
                    v-bind="$attrs"
                    :disabled="disabled"
                    :class="[wrapper?.inputBorderColorClass, inputClass]"
                    @input="updateInput"
                    class="w-full niw-dynamic-border"
                />
                <InputGroupAddon>
                    <i v-if="isLoading" class="ph ph-circle-notch pi-spin text-xl"></i>
                    <slot v-else name="append-icon" />
                </InputGroupAddon>
            </InputGroup>

            <!-- render component with no v-tooltip directive if tooltip is null -->
            <InputText
                v-else v-bind="$attrs" :disabled="disabled" :class="[wrapper?.inputBorderColorClass, inputClass]"
                @input="updateInput" :modelValue="modelValue" class="w-full niw-dynamic-border"
            />
        </template>
        <template #bottom>
            <slot />
        </template>
    </NimbusInputWrapper>
</template>

<script>
import InputText from 'primevue/inputtext';
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';

import { ref } from 'vue';

export default {
    name: 'NimbusInputText',
    components: {
        InputText,
        NimbusInputWrapper,
    },
    props: {
        inputClass: {
            type: String,
        },
        modelValue: {
            type: String,
        },
        tooltip: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['rightIconClick', 'update:modelValue'],
    setup(props, { emit }) {
        const wrapper = ref(null);

        /**
         * Emit 'v-model:update' event for binding
         * @param event
         */
        const updateInput = event => {
            emit('update:modelValue', event.target.value);
        };

        return {
            /**
             * Data
             */
            wrapper,

            /**
             * Function
             */
            updateInput,
        };
    },
};
</script>