const page = 'roles-permission';

export default [
    {
        path: '/setup/' + page,
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/setup/roles_permissions/Index'),
        meta: {
            auth: true,
            permission: ['role.access', 'permission.access'],
            title: 'Roles and Permission',
            prefix: `${page}-main`
        },
        children: [
            {
                path: '/role',
                name: 'roles-permission-roles',
                exact: true,
                component: () => import('@/pages/security/role/Main.vue'),
                meta: {
                    title: 'Role',
                    auth: true,
                    permission: ['role.access'],
                },
            },
            {
                path: '/role/create',
                name: 'role.create',
                component: () => import('@/pages/security/role/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['role.create'],
                    title: 'Create Role',
                },
            },
            {
                path: '/role/edit/:id',
                name: 'role.edit',
                component: () => import('@/pages/security/role/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['role.edit'],
                    title: 'Edit Role',
                },
            },
            {
                path: '/role/:id',
                name: 'role.id.access',
                exact: true,
                component: () => import('@/pages/security/role/Single.vue'),
                meta: {
                    auth: true,
                    permission: ['role.view'],
                    wrapped: true,
                    title: 'View Role',
                },
            },
            {
                path: '/role/:id/user',
                name: 'role.id.user.access',
                exact: true,
                component: () => import('@/pages/security/role/AccountList.vue'),
                meta: {
                    auth: true,
                    permission: ['role.id.user.access'],
                    wrapped: true,
                },
            },
            {
                path: '/role-group',
                name: 'Role Group Page Listing',
                exact: true,
                component: () => import('@/pages/security/role_group/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['role-group.access'],
                },
            },
            {
                path: '/role-group/:id',
                name: 'Role Group View Page',
                exact: true,
                component: () => import('@/pages/security/role_group/View.vue'),
                meta: {
                    auth: true,
                    permission: ['role-group.view'],
                },
            },
            {
                path: '/permissions',
                name: 'roles-permission-permissions',
                exact: true,
                component: () => import('@/pages/permission/Main.vue'),
                meta: {
                    auth: true,
                    permission: ['permission.access'],
                    title: 'Permissions',
                },
            },
            {
                path: '/permission/:id',
                name: 'View Permission',
                exact: true,
                component: () => import('@/pages/permission/View.vue'),
                meta: {
                    auth: true,
                    permission: ['permission.view'],
                    title: 'View Permissions',
                },
            },
            {
                path: '/permission/create',
                name: 'Permission Create',
                exact: true,
                component: () => import('@/pages/permission/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['permission.create'],
                    title: 'Create Permission',
                },
            },
            {
                path: '/permission/:id/edit',
                name: 'Permission Edit',
                exact: true,
                component: () => import('@/pages/permission/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['permission.edit'],
                    title: 'Edit Permissions',
                }
            },
        ]
    },

];
