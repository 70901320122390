import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const
    page = 'account',
    pageGroupKey = 'page_group_accounts';

const accountRoute = [
    {
        path: '/account-list',
        name: 'accounts',
        exact: true,
        component: () => import('@/pages/account/account-list/AccountMain'),
        meta: {
            auth: true,
            permission: ['account.access'],
            redirect: `${page}-summary`,
        }
    },
    {
        path: '/account/security',
        name: 'Accounts Security',
        exact: true,
        component: () => import('@/pages/account/Security/Index'),
        meta: {
            auth: true,
            permission: ['account.security'],
        },
    },
    {
        path: '/account/:accountId',
        name: 'account.show',
        exact: true,
        component: () => import('@/pages/account/account-list/Index'),
        meta: {
            auth: true,
            permission: ['account.view'],
        },
        children: [
            {
                path: 'summary',
                name: `${page}-summary`,
                exact: true,
                component: () => import('@/pages/account/account-list/summary/View'),
                meta: {
                    auth: true,
                    permission: ['account.view'],
                    title: 'Account Summary',
                    prefix: `${page}-summary`,
                    breadcrumbLabel: 'Summary',
                },
            },
            {
                path: 'contacts',
                name: `${page}-contacts`,
                exact: true,
                component: () => import('@/pages/account/account-list/contact/Index'),
                meta: {
                    auth: true,
                    permission: ['account.view'],
                    title: 'Account Contacts',
                    prefix: `${page}-contacts`,
                    breadcrumbLabel: 'Contacts',
                },
            },
            {
                path: 'invoices',
                name: `${page}-invoices`,
                exact: true,
                component: () => import('@/pages/account/account-list/invoices/regular/InvoiceMain'),
                meta: {
                    auth: true,
                    permission: ['invoices.access'],
                    title: 'Invoices',
                    prefix: `${page}-invoices`,
                },
            },
            {
                path: 'payments',
                name: `${page}-payments`,
                exact: true,
                component: () => import('@/pages/account/account-list/payments/Main'),
                meta: {
                    auth: true,
                    permission: ['payments.access'],
                    title: 'Payments',
                    prefix: `${page}-payments`,
                },
            },
            {
                path: 'payments/history',
                name: `${page}-payment-history`,
                exact: true,
                component: () => import('@/pages/account/account-list/payments/payments/PaymentMain'),
                meta: {
                    auth: true,
                    permission: ['payments.access'],
                    title: 'Payment History',
                    prefix: `${page}-payment-history`,
                    routeNode: {
                        parentNode: `${page}-payments`,
                        children: [],
                    },
                },
            },
            {
                path: 'payments/history/:id/details',
                name: `${page}-payment-history-details`,
                exact: true,
                component: () => import('@/pages/account/account-list/payments/payments/PaymentDetails'),
                meta: {
                    auth: true,
                    permission : ['payments.access', 'portal.payments.access'],
                    title : 'Payment Details',
                    prefix: `${page}-payment-history-details`,
                },
            },
            {
                path: 'payments/take-payment',
                name: `${page}-take-payment`,
                exact: true,
                component: () => import('@/pages/account/account-list/payments/take-payments/TakePaymentMain'),
                meta: {
                    auth: true,
                    permission: ['payments.access'],
                    title: 'Take Payments',
                    prefix: `${page}-take-payment`,
                    routeNode: {
                        parentNode: `${page}-payments`,
                        children: [],
                    },
                },
            },
            {
                path: 'transactions',
                name: `${page}-transactions`,
                exact: true,
                component: () => import('@/pages/account/account-list/transactions/Main'),
                meta: {
                    auth: true,
                    permission: ['transaction.access'],
                    title: 'Transactions',
                    prefix: `${page}-transactions`,
                },
            },
            {
                path: 'payment-methods',
                name: `${page}-payment-methods`,
                exact: true,
                component: () => import('@/pages/account/account-list/payment-methods/PaymentMethodMain'),
                meta: {
                    auth: true,
                    permission: ['payment-methods.access'],
                    title: 'Payment Methods',
                    prefix: `${page}-payment-methods`,
                },
            },
            {
                path: 'payment-methods/credit-cards',
                name: `${page}-credit-cards`,
                exact: true,
                component: () => import('@/pages/account/account-list/payment-methods/credit-cards/CreditCardMain.vue'),
                meta: {
                    auth: true,
                    title : 'Credit Cards',
                    prefix: page,
                    routeNode: {
                        parentNode: `${page}-payment-methods`,
                        children: [],
                    },
                },
            },
            {
                path: 'payment-methods/bank-accounts',
                name: `${page}-bank-accounts`,
                exact: true,
                component: () => import('@/pages/account/account-list/payment-methods/bank-accounts/BankAccountMain.vue'),
                meta: {
                    auth: true,
                    title : 'Bank Accounts',
                    prefix: page,
                    routeNode: {
                        parentNode: `${page}-payment-methods`,
                        children: [],
                    },
                },
            },
        ]
    },
    {
        path: '/account/create/:stage',
        name: 'account.create',
        exact: true,
        component: () => import('@/pages/account/Create/Index'),
        meta: {
            auth: true,
            permission: ['account.create'],
        },
    },
    /**
     * This is added here for testing as permissions on route (routes/end_user_portal/index.js) is not yet ready
     */
    {
        path: '/account/account-security',
        name: 'End-User Portal - Account Security',
        exact: true,
        component: () => import('@/pages/end_user_portal/account_security/Index'),
        meta: {
            auth: true,
            permission: ['account.access'],
        },
    },
    {
        path: '/account/account-summary',
        name: 'End-User Portal - Account Summary',
        exact: true,
        component: () => import('@/pages/end_user_portal/account_summary/Main'),
        meta: {
            auth: true,
            permission: ['account.access'],
        },
    },
];

export default applyPageGroupKeyToParents(accountRoute, { pageGroupKey });
