const page = 'ticket-setup';

export default [
    {
        path: '/setup/' + page,
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/setup/ticket_setup/Index'),
        meta: {
            auth: true,
            permission: ['ticket.queue.access', 'ticket.category.access', 'ticket.sub-category.access'],
            title: 'Profit Center Setup',
            prefix: `${page}-main`
        },
        children: [
            {
                path: '/ticket/queue',
                name: 'ticket-setup-queue',
                exact: true,
                component: () => import('@/pages/ticket_queue/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['ticket.queue.access'],
                    title: 'Ticket Queue',
                },
            },
            {
                path: '/ticket/category',
                name: 'ticket-setup-category',
                exact: true,
                component: () => import('@/pages/ticket_category/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['ticket.category.access'],
                    title: 'Ticket Category',
                },
            },
            {
                path: '/ticket/sub-category',
                name: 'ticket-setup-sub-category',
                exact: true,
                component: () => import('@/pages/ticket_sub_category/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['ticket.sub-category.access'],
                    title: 'Ticket Sub-Category',
                },
            },
        ]
    },

];
