const page = 'profit-centre-setup';

export default [
    {
        path: '/setup/' + page,
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/setup/profit_centre_setup/Index'),
        meta: {
            auth: true,
            permission: ['profit-centre.group.access', 'profit-centre.type.access'],
            title: 'Profit Center Setup',
            prefix: `${page}-main`
        },
        children: [
            {
                path: '/profit-centre/group',
                name: 'profit-center-group',
                exact: true,
                component: () => import('@/pages/profit_centre/group/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.group.access'],
                    title: 'Profit Center Group',
                },
            },
            {
                path: '/profit-centre/group/create',
                name: 'Profit Centre Group Create',
                exact: true,
                component: () => import('@/pages/profit_centre/group/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.group.create'],
                    title: 'Profit Center Create',
                },
            },
            {
                path: '/profit-centre/type',
                name: 'profit-center-type',
                exact: true,
                component: () => import('@/pages/profit_centre/type/Index.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.type.access'],
                    title: 'Profit Center Type',
                },
            },
            {
                path: '/profit-centre/type/create',
                name: 'Profit Centre Type Create',
                exact: true,
                component: () => import('@/pages/profit_centre/type/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.type.create'],
                    title: 'Profit Center Type Create',
                },
            },
            {
                path: '/profit-centre/type/:id/edit',
                name: 'Profit Centre Type Edit',
                exact: true,
                component: () => import('@/pages/profit_centre/type/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.type.edit'],
                    title: 'Profit Center Type Edit',
                },
            },
            {
                path: '/profit-centre/group/:id/edit',
                name: 'Profit Centre Group Edit',
                exact: true,
                component: () => import('@/pages/profit_centre/group/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['profit-centre.group.edit'],
                    title: 'Profit Center Group Edit',
                },
            },
        ]
    },

];
