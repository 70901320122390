/**
 * Set a key in Local Storage
 *
 * @param key
 * @param value
 */
export function setLSItem(key, value) {
    localStorage.setItem(key, value);
}

/**
 * Get the key in Local Storage
 *
 * @param key
 * @returns {string}
 */
export function getLSItem(key) {
    return localStorage.getItem(key);
}

/**
 * Remove a key in Local Storage
 *
 * @param key
 */
export function deleteLSItem(key) {
    localStorage.removeItem(key);
}

/**
 * Force the application to clear the storage except on the selected keys
 */
export function forceRemoveDataInAutoLogout() {
    for (let [key, value] of Object.entries(localStorage)) {
        if (!key.includes('translation') && !key.includes('nimbus-selected-language')) {
            deleteLSItem(key);
        }
    }
}
