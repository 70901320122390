/**
 * Parse multiple date formats
 *
 * @param input
 * @param format
 * @returns {null|Date}
 */
export function parseDate(input, format = null) {
    // Check if input is a valid non-empty string
    if (typeof input !== 'string' || !input.trim()) {
        return null;
    }

    // List of patterns for different date formats
    const datePatterns = [
        /^(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?) (\d{4})$/i, // Jan 2025, January 2025
        /^(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?) (\d{1,2})$/i, // Jan 25, January 25
        /^(\d{1,2})[\/\-\s+](\d{4})$/, // 01/2025, 01-2025, 01 2025
        /^(\d{1,2})[\/\-\s+](\d{2})$/, // 01/25, 01-25, 01 25
    ];

    for (const pattern of datePatterns) {
        const match = input.match(pattern);
        if (match) {
            const [_, monthOrDay, yearOrDay] = match;
            let date;

            if (pattern === datePatterns[0] || pattern === datePatterns[1]) {
                date = new Date(`${monthOrDay} 1, ${yearOrDay}`);
            } else if (pattern === datePatterns[2]) {
                date = new Date(`${yearOrDay}-${monthOrDay}-01`);
            } else if (pattern === datePatterns[3]) {
                // Parse as MM/DD format (current year)
                if (format === 'MM/DD') {
                    date = new Date(`${new Date().getFullYear()}-${monthOrDay}-${yearOrDay}`);
                } else {
                    // Default to MM/YY
                    date = new Date(`20${yearOrDay}-${monthOrDay}-01`);
                }
            }

            return !isNaN(date) ? date : null;
        }
    }
    return null;
}
