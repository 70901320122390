<template>
  <div class='grid'>
    <div class='col-6'>
      <NimbusInputPassword v-if="!hideInputBox" :feedback="false" v-model="email_auth_code" required :errorMessage="errors.code" right-icon="pi"
                           :placeholder="t('two_fa_verify_code_title', 'Enter the code to verify')" @keyup.enter="submitButton()">
        <template #toggle> this is a slot </template>
      </NimbusInputPassword>
      <span v-if="!hideResendTimer">
        <Button v-if="resend_timer == 0" class="mr-5 p-button-link" label="Resend" @click="sendEmailAuthenticationCodeApi"/>
        <small v-else >Resend OTP code in {{formattedTimer}} seconds.</small>
      </span>
    </div>
    <div class='col-12' v-if="!hideVerifyCodeButton">
      <Button class='' @click='submitButton()' :label="t('two_fa_verify_code_button', 'Verify Code')" />
    </div>
  </div>
</template>

<script>
import t from '@/service/Translate';
import { ref, watch } from 'vue';
import NimbusInputText from '@/components/nimbus/NimbusInputText';
import { verifyUserAuthenticationCodeByUrl
} from '@/service/TwoFactorAuthService';
import NimbusInputPassword from "@/components/nimbus/NimbusInputPassword.vue"
import moment from "moment";
import { useOtpStore } from '@/stores/otp-store';

export default {
  name: 'BlackBox Email OTP Form',
  components: {NimbusInputPassword},
  props: {
    validateTokenApi: {
      type: String,
      required: true
    },
    resendTimerInMinutes: {
      type: String,
      default: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER_IN_MINUTES
    },
    resendTimer: {
      type: String,
      default: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER
    },
    otpTokenRequestId: {
      default: ''
    },
    hideVerifyCodeButton : {
        type: Boolean,
        default : false
    },
    hideInputBox : {
        type: Boolean,
        default : false
    },
    hideResendTimer : {
        type: Boolean,
        default : false
    },
    automaticSendToken : {
        type : Boolean,
        default : true
    }
  },
  emits: ['submitVerificationCodeEvent', 'sendEmailAuthenticationCodeApi'],
  setup(props, { emit }) {
    let email_auth_code = ref('');
    let loading = ref(false);
    let errors = ref([]);
    let formattedTimer = ref(null);
    let resend_timer_interval = ref(null);
    let resend_timer_in_minutes = ref(props.resendTimerInMinutes);
    let resend_timer = ref(props.resendTimer);
    const otpStore = useOtpStore();

    const startTimer = () => {
      let start = moment(resend_timer_in_minutes.value, "mm:ss");
      formattedTimer.value = start.format("mm:ss");
      resend_timer_interval.value = setInterval(() => {
        if (resend_timer.value == 0) {
          clearInterval(resend_timer_interval.value);
        } else {
          resend_timer.value--;
          formattedTimer.value = start.subtract(1, "second").format("mm:ss");
        }
      }, 1000);
    }

    startTimer();
    const submitButton = async () => {

      try {
        loading.value = true
        let params = {
          otp_code: email_auth_code.value,
          token_request_id: props.otpTokenRequestId,
          method_id: 2 // NIMBUS
        };
        let response = await verifyUserAuthenticationCodeByUrl(props.validateTokenApi, params);
        emit('submitVerificationCodeEvent', true, response);
      } catch (error) {
        if(error.response.status == 401) {
          emit('submitVerificationCodeEvent', false, error);
        } else {
          loading.value = false
          errors.value = error.response.data.errors ?? error.response.data;
        }
      }
    };
    const sendEmailAuthenticationCodeApi = async () => {
      await emit('sendEmailAuthenticationCodeApi');
      clearInterval(resend_timer_interval.value);
      resend_timer_in_minutes.value = props.resendTimerInMinutes;
      resend_timer.value = props.resendTimer;
      startTimer();
    }

    watch(email_auth_code, async (newValue, oldValue) => {
      if (newValue === "") {
        errors.value = [];
        otpStore.setOtpValidationResponse(null);
      }
    });

    watch(() => otpStore.otpValidationResponse, (newValue, oldValue) => {
      if (newValue != null) {
        errors.value = newValue;
      }
    });

    return {
      t,
      submitButton,
      email_auth_code,
      errors,
      resend_timer,
      formattedTimer,

      sendEmailAuthenticationCodeApi
    };
  },
};
</script>

<style scoped></style>
