<template>
    <div class='grid'>
        <div class='col-12'>
            <p>{{ twofa_code_heading }} </p>
        </div>
        <div class='col-6'>
            <NimbusInputText :placeholder="t('two_fa_verify_code_title', 'Enter the code to verify')"
                             v-model='twofa_code' required :errorMessage="errors.code" @keyup.enter="sendVerificationCode"></NimbusInputText>
        </div>
        <div class='col-12'>
            <Button class='' @click='sendVerificationCode' :label="t('two_fa_verify_code_button', 'Verify Code')" />
        </div>
    </div>
</template>

<script>
import t from '@/service/Translate';
import { ref } from 'vue';
import NimbusInputText from '@/components/nimbus/NimbusInputText';
import { verify2FACode } from '@/service/TwoFactorAuthService';

export default {
    name: 'TwoFAForm',
    components: { NimbusInputText },
    props: {
        twofa_code_heading: {
            type: String,
        },
    },
    emits: ['twoFAEvents'],
    setup(props, { emit }) {
        const twofa_code = ref('');
        const loading = ref(false);
        const errors = ref([]);

        const sendVerificationCode = async () => {
            try {
                loading.value = true
                let params = {
                    code: twofa_code.value,
                };

                await verify2FACode(params);
                emit('twoFAEvents', true);
            } catch (error) {
                if(error.response.status == 401) {
                    emit('twoFAEvents', false);
                } else {
                    loading.value = false
                    errors.value = error.response.data.errors;
                }
            }
        };

        return {
            t,
            sendVerificationCode,
            twofa_code,
            errors,
        };
    },
};
</script>

<style scoped></style>
