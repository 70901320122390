<template>
    <div class="grid center">
        <div class="col-12" style="margin-top: 100px;">
            <img src="assets/layout/images/Lock.svg" alt="Nimbus Logo" style='width:200px;'/>
            <h1 class="nimbus-text-gray">{{ $t("lbl_access_denied", "Access Denied") }}</h1>
            <h2 class="nimbus-text-gray-faded">{{ $t("lbl_error_403", "Error 403") }}</h2>

            <h4 class="nimbus-text-gray">
                {{ $t("lbl_no_permission", "You do not have necessary permissions") }} <br/>
                <small
                    class="nimbus-text-gray-faded">{{ $t("lbl_use_other_account", "Log in as a different account or contact your administrator")
                    }}</small>
            </h4>
            <Button :label="`${$t('btn_go_back_to', 'Go back to')} ${prevPage}`"
                    class="p-button-outlined nimbus-orange mr-5" v-if="shown" @click="goToPage()"/>
        </div>
    </div>

</template>

<script>
import UserAuthenticationService from '../service/UserAuthenticationService';

export default {
    data() {
        return {
            userService: null,
            prevPage: "",
            shown: false,
            page: null
        }
    },
    mounted() {
        this.userService = new UserAuthenticationService();

        if (this.userService.getPreviousVisitedUrl()) {

            this.page = JSON.parse(this.userService.getPreviousVisitedUrl())

            //Default pages return to ticket or Dashboard
            if (this.page.fullPath == "/"
                || this.page.fullPath == "/access-denied"
                || this.page.fullPath == "/error"
                || this.page.fullPath == "/not-found"
                || this.page.path == "/login"
            ) {
                this.prevPage = "user-profile"
                this.page.fullPath = "/user-profile"
            } else {
                this.prevPage = this.page.fullPath.replace("/", "");
            }

            this.shown = true;
        }
    },
    methods: {
        goToPage() {
            this.$router.push(this.page.fullPath);
        }
    }
};
</script>
