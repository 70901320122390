/**
 * Utility function to recursively find the top-level parent node.
 *
 * @param {Object} currentRoute - The current route object.
 * @param {Array} allRoutes - The full list of routes.
 * @returns {Object} The top-level parent route object.
 */
export const getTopLevelParent = (currentRoute, allRoutes) => {
    // Helper function to find the page that matches the current route URI
    const findPage = (routeUri) => {
        for (const group of allRoutes) {
            if (group?.page_group_key === routeUri.meta?.pageGroupKey) {
                return true;
            }
        }
        return null;
    };

    // Start by finding the current page and its group
    return findPage(currentRoute);
}

/**
 * Set the "page group key" to the parent route dynamically
 *
 * Note:
 * The term "parent route" refers only to the way routes are grouped in the route configuration (i.e., routes with
 * children in routes/modules/account.js, etc). It does not necessarily reflect the URL structure.
 *
 * @param routes
 * @param parentMeta
 * @returns {*}
 */
export const applyPageGroupKeyToParents = (routes, parentMeta = {}) => {
    return routes.map(route => {
        route.meta = {
            ...route.meta,
            ...parentMeta,
        }

        return route;
    })
}