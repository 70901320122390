import { createDataTableStore } from "@/stores/datatables/filterStore";
import { getUsablePayMethodsApi } from '@/service/PaymentMethod';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { get } from '@/core/apis';
import { useAutoLogout } from '@/stores/auto-logout';
import { deleteLSItem } from '@/service/NimbusLSService';
import { INVOICE_COLUMN_DATATABLE_SETTING_KEY } from '@/components/invoice/invoiceConstants';
import { getExpiryTime, getWarningTime } from '@/service/AutoLogoutService';
import { getCurrencySetting } from '@/service/CurrencyService';

export const usePaymentMethodCreditCardStore = createDataTableStore('payment-method-credit-card-list');
export const usePaymentMethodBankAccountStore = createDataTableStore('payment-method-bank-account-list');

export const PAYMENT_METHOD_ID_BANK = 1;
export const PAYMENT_METHOD_ID_CREDIT_CARD = 10;


export const paymentMethodStore = defineStore(
    'payment-method-store',
    () => {
        /**
         * State
         */
        const usablePaymentMethod = ref({});
        const isCreditCardUsable = ref(false);
        const isBankUsable = ref(false);
        /**
         * Getters
         */

        /**
         * Actions
         */

        /** Get Usable Payment Method**/
        const getUsablePaymentMethod = async () => {
            usablePaymentMethod.value = await getUsablePayMethodsApi()
            isBankUsable.value = usablePaymentMethod.value.data.some((element) => parseInt(element.payment_method_id) === PAYMENT_METHOD_ID_BANK)
            isCreditCardUsable.value = usablePaymentMethod.value.data.some((element) => parseInt(element.payment_method_id) === PAYMENT_METHOD_ID_CREDIT_CARD)
        }

        return {
            /**
             * State
             */
            usablePaymentMethod,
            isBankUsable,
            isCreditCardUsable,

            /**
             * Getters
             */

            /**
             * Actions
             */
            getUsablePaymentMethod,
        };
    },
    {
        persist: true,
    }
);
