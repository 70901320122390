import {createApp, h} from 'vue';
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {PiniaSharedState} from 'pinia-shared-state';
import router from './routes'; // DEV-912: importing routes/index.js to load all the routes registered
import App from './App.vue';
import axios from 'axios';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import ProgressSpinner from 'primevue/progressspinner';
import VueAxios from 'vue-axios';
import DataTableCustom from './components/DataTableCustom';

import CodeHighlight from './AppCodeHighlight';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';

import Access from '@/pages/Access';
import Error from '@/pages/Error';
import Login from '@/pages/login/Index.vue';
import NotFound from '@/pages/NotFound';
import Help from '@/pages/Help';

import {Permissions} from '@/directives/permissions';
import translate from '@/service/Translate';
import {Constants} from '@/service/Constants';
import LanguageService from '@/service/LanguageService';
import core from '@/core';
import AccountVerification from '@/pages/end_user_portal/account_verification/Index.vue';
import ResetPassword from '@/pages/end_user_portal/ResetPassword';
import {
    getUserPortalSettings,
    setCacheUserPortalSettings,
    unsetCacheUserPortalSettings
} from '@/service/EndUserPortalService';
import {setThemeColor} from '@/service/NimbusUtilities';
import {getCurrencyByServiceProvider, getCurrencySetting} from '@/service/CurrencyService';
import ForgotPassword from '@/pages/forgot_password/Index.vue';
import CreateNewPassword from '@/pages/forgot_password/CreateNewPassword.vue';
import ExpiredLink from '@/pages/forgot_password/ExpiredLink.vue';
import {setEndUserPortalHost, getEndUserPortalHost} from '@/service/EndUserPortalService';
import OTPCreditCardVerification from '@/pages/finance/payment/payment-methods/credit-cards/OTPCreditCardVerification.vue';

const initApp = () => {
    const app = createApp({
        computed: {
            ViewComponent() {

                /**
                 * Override the view component if the route has params
                 */
                if (this.$route.name == "user.create.password") {
                    return CreateNewPassword;
                }

                if (this.$route.name == "change.payment.method.otp") {
                    return OTPCreditCardVerification;
                }

                switch (this.$route.path) {
                    case '/reset-password':
                        return ResetPassword;
                    case '/account-verified':
                        return AccountVerification;
                    case '/login':
                        return Login;
                    case '/error':
                        return Error;
                    case '/access':
                        return Access;
                    case '/not-found':
                        return NotFound;
                    case '/help':
                        return Help;
                    case '/forgot-password':
                        return ForgotPassword;
                    case '/expired':
                        return ExpiredLink;
                    default:
                        return App;
                }
            },
        },
        render() {
            return h(this.ViewComponent);
        },
        beforeMount() {
            this.getTranslations();
        },
        methods: {
            async getTranslations() {
                const languageService = new LanguageService();
                if (languageService.getTranslationExpirationDate() <= Date.now()) {
                    await languageService.callApiTranslation();
                }
            },
        },
    });

    app.config.devtools = true;

    const pinia = createPinia();
    pinia.use(piniaPluginPersistedstate);
    pinia.use(
        PiniaSharedState({
            enable: false,
            initialize: false,
            type: 'localstorage',
        })
    );
    app.use(pinia);

    app.use(PrimeVue, {ripple: true});
    app.use(ConfirmationService);
    app.use(ToastService);
    app.use(router);
    app.use(VueAxios, axios);

    // translation config
    app.config.globalProperties.$t = translate;
    app.config.globalProperties.$constants = Constants;
    //load the core here and add the path as argument
    core(app);
    axios.defaults.baseURL = process.env.VUE_APP_NIMBUS_API_URL;

    app.directive('tooltip', Tooltip);
    app.directive('ripple', Ripple);
    app.directive('code', CodeHighlight);
    app.directive('badge', BadgeDirective);

    // custom directive
    app.directive('can', Permissions);

    app.component('DataTableCustom', DataTableCustom);
    app.component('Accordion', Accordion);
    app.component('AccordionTab', AccordionTab);
    app.component('AutoComplete', AutoComplete);
    app.component('Avatar', Avatar);
    app.component('AvatarGroup', AvatarGroup);
    app.component('Badge', Badge);
    app.component('Breadcrumb', Breadcrumb);
    app.component('Button', Button);
    app.component('Calendar', Calendar);
    app.component('Card', Card);
    app.component('Carousel', Carousel);
    app.component('Chart', Chart);
    app.component('Checkbox', Checkbox);
    app.component('Chip', Chip);
    app.component('Chips', Chips);
    app.component('ColorPicker', ColorPicker);
    app.component('Column', Column);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('ConfirmPopup', ConfirmPopup);
    app.component('ContextMenu', ContextMenu);
    app.component('DataTable', DataTable);
    app.component('DataView', DataView);
    app.component('DataViewLayoutOptions', DataViewLayoutOptions);
    app.component('Dialog', Dialog);
    app.component('Divider', Divider);
    app.component('Dropdown', Dropdown);
    app.component('Editor', Editor);
    app.component('Fieldset', Fieldset);
    app.component('FileUpload', FileUpload);
    app.component('Galleria', Galleria);
    app.component('Image', Image);
    app.component('InlineMessage', InlineMessage);
    app.component('Inplace', Inplace);
    app.component('InputMask', InputMask);
    app.component('InputNumber', InputNumber);
    app.component('InputSwitch', InputSwitch);
    app.component('InputText', InputText);
    app.component('Knob', Knob);
    app.component('Listbox', Listbox);
    app.component('MegaMenu', MegaMenu);
    app.component('Menu', Menu);
    app.component('Menubar', Menubar);
    app.component('Message', Message);
    app.component('MultiSelect', MultiSelect);
    app.component('OrderList', OrderList);
    app.component('OrganizationChart', OrganizationChart);
    app.component('OverlayPanel', OverlayPanel);
    app.component('Paginator', Paginator);
    app.component('Panel', Panel);
    app.component('PanelMenu', PanelMenu);
    app.component('Password', Password);
    app.component('PickList', PickList);
    app.component('ProgressBar', ProgressBar);
    app.component('RadioButton', RadioButton);
    app.component('Rating', Rating);
    app.component('SelectButton', SelectButton);
    app.component('ScrollPanel', ScrollPanel);
    app.component('ScrollTop', ScrollTop);
    app.component('Slider', Slider);
    app.component('Sidebar', Sidebar);
    app.component('Skeleton', Skeleton);
    app.component('SpeedDial', SpeedDial);
    app.component('SplitButton', SplitButton);
    app.component('Splitter', Splitter);
    app.component('SplitterPanel', SplitterPanel);
    app.component('Steps', Steps);
    app.component('TabMenu', TabMenu);
    app.component('TabView', TabView);
    app.component('TabPanel', TabPanel);
    app.component('Tag', Tag);
    app.component('Textarea', Textarea);
    app.component('TieredMenu', TieredMenu);
    app.component('Timeline', Timeline);
    app.component('Toast', Toast);
    app.component('Toolbar', Toolbar);
    app.component('ToggleButton', ToggleButton);
    app.component('Tree', Tree);
    app.component('TreeSelect', TreeSelect);
    app.component('TreeTable', TreeTable);
    app.component('TriStateCheckbox', TriStateCheckbox);
    app.component('InputGroup', InputGroup);
    app.component('InputGroupAddon', InputGroupAddon);
    app.component('Row', Row);
    app.component('ColumnGroup', ColumnGroup);
    app.component('ProgressSpinner', ProgressSpinner);

    app.mount('#app');
};

/**
 * Get Initial User Portal Settings
 */
let hostname = window.location.hostname;
setEndUserPortalHost(hostname);

const initUserPortal = async () => {
    let host = getEndUserPortalHost();

    await getUserPortalSettings(host)
        .then(async(response) => {
            await setCacheUserPortalSettings(response.data);
            let nimbusLanguage = localStorage.getItem('nimbus-selected-language')
            const languageService = new LanguageService();
            // set selected language
            if (!nimbusLanguage) {
                // not set
                const language = ('en-' + response.data.country['iso3166_2char_code']).toLowerCase();
                languageService.setSelectedLanguage(language)
            }
            languageService.callApiTranslation();

            setThemeColor('primary', response.data.main_color);
            setThemeColor('secondary', response.data.hover_color);
            setThemeColor('tertiary', response.data.active_color);

            initApp();
        })
        .catch(() => {
            console.error('User Portal Settings not found');

            unsetCacheUserPortalSettings();
            initApp();
        });
};

// If hostname is not UI url, validate user portal
if (typeof process.env.VUE_APP_NEUP_URL !== "undefined") {
    setEndUserPortalHost(process.env.VUE_APP_NEUP_URL);
}

initUserPortal();
