import { useUserStore } from '@/stores/user-store';
import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const
    page = 'finance-payment-methods',
    pageGroupKey = 'page_group_finance_portal';

const routes = [
    {
        path: '/payment-methods',
        name: `${page}-accounts`,
        exact: true,
        component: () => import('@/pages/account/account-list/AccountMain'),
        meta: {
            auth: true,
            permission : ['portal.payment-methods.access', 'payment-methods.access'],
            prefix: page,
            redirect: `${page}-main`,
            title : 'Accounts'
        },
        beforeEnter: (to, from, next) => {
            const userStore = useUserStore();
            if (userStore.isEup) {
                next({ name: `${page}-main`, params: { accountId: userStore.userData.accountId } });
            } else {
                next();
            }
        },
    },
    {
        path: '/payment-method',
        name: `${page}-main`,
        exact: true,
        redirect: { name: `${page}-credit-cards` },
        component: () => import('@/pages/finance/payment/payment-methods/PaymentMethodMain.vue'),
        meta: {
            auth: true,
            permission: ['portal.payment-methods.access', 'payment-methods.access'],
            title : 'Payment Methods'
        },
        children: [
            {
                path: 'credit-cards',
                name: `${page}-credit-cards`,
                exact: true,
                component: () => import('@/pages/finance/payment/payment-methods/credit-cards/CreditCardMain.vue'),
                meta: {
                    auth: true,
                    title : 'Credit Cards',
                    prefix: page
                },
            },
            {
                path: 'bank-accounts',
                name: `${page}-bank-accounts`,
                exact: true,
                component: () => import('@/pages/finance/payment/payment-methods/bank-accounts/BankAccountMain.vue'),
                meta: {
                    auth: true,
                    title : 'Bank Accounts',
                    prefix: page
                },
            },
        ]
    },
]

export default applyPageGroupKeyToParents(routes, { pageGroupKey });
