export default [
    {
        path: '/user-profile',
        name: 'user.profile',
        exact: true,
        component: () => import('@/pages/user_profile/View'),
        meta: {
            auth: false,
            permission: ['user-profile.access']
        },
    },
    {
        path: '/user-profile/auth',
        name: 'user-profile.auth',
        exact: true,
        component: () => import('@/pages/user_profile/Security'),
        meta: {
            auth: true,
            permission: ['user-profile.access']
        },
    },
    {
        path: '/user-profile/edit',
        name: 'user-profile.edit',
        exact: true,
        component: () => import('@/pages/user_profile/Edit'),
        meta: {
            auth: true,
            permission: ['user-profile.access']
        },
    },
];