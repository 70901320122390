import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { get } from '@/core/apis';
import { getExpiryTime, getWarningTime } from '@/service/AutoLogoutService';
import { deleteLSItem } from '@/service/NimbusLSService';
import { useAutoLogout } from '@/stores/auto-logout';
import { getCurrencySetting } from '@/service/CurrencyService';
import { INVOICE_COLUMN_DATATABLE_SETTING_KEY } from '@/components/invoice/invoiceConstants';
import { paymentMethodStore } from '@/stores/datatables/paymentMethodStore';

export const useUserStore = defineStore(
    'user-store',
    () => {
        /**
         * State
         */
        const userData = ref({});
        const userTimezone = ref({});
        const userSettings = ref({});
        const latestTokenRequest = ref({});
        const currencySettings = ref({});

        /**
         * Getters
         */
        const getUserTimezoneDisplay = computed(() => {
            return userTimezone.value ? `${userTimezone.value.abbr} ${userTimezone.value.identifier} ${userTimezone.value.name}` : null;
        });

        /**
         * get the email domain of logged user
         * @type {ComputedRef<*>}
         */
        const getUserDataEmailDomain = computed(() => userData.value.emailDomain);

        /**
         * Checked if the Logged User is an Emersion Account
         *
         * @type {ComputedRef<*>}
         */
        const isEmersionUser = computed(() => userData.value.isEmersion);

        const isBankAccountTypeRequired = computed(() => userData.value.isBankAccountTypeRequired)

        const isEup = computed(() => userData.value.isEndUser);

        const accountId = computed(() => userData.value.accountId);
        const DEFAULT_INVOICE_ID_SHOW_BOTH = 0;
        const INVOICE_ID_SHOW_ORIGINAL_INVOICE_ID = 2;
        /**
         * Actions
         */

        /** Set the details for user upon authenticated **/
        const setUserAuthenticatedDetails = (data) => {
            userData.value = data;
        };

        /** Set latest token request */
        const setLatestTokenRequest = (data) => {
            latestTokenRequest.value = data;
        }

        /**
         * Set the user timezone upon login
         *
         * @returns {Promise<void>}
         */
        const getUserTimezone = async () => {
            try {
                userTimezone.value = await get('account/timezone');
            } catch (error) {
                console.log(error);
            }
        };

        /**
         * Get the user account settings
         *
         * @returns {Promise<void>}
         */
        const getUserAccountSettings = async () => {
            try {
                const autoLogoutSTore = useAutoLogout();
                const response = await get('account/setting');
                userSettings.value = await response.data;

                deleteLSItem('auto-logout');
                autoLogoutSTore.setSettingsTimer();

                // Payment Method
                await paymentMethodStore().getUsablePaymentMethod();
            } catch (error) {
                console.error(error);
            }
        };

        /**
         * Get a single setting object via key
         *
         * @param key
         * @returns {*}
         */
        const getSettingViaKey = (key) => {
            return userSettings.value?.find((v) => {
                return v.setting && v.setting.key === key;
            });
        };

        const getNimbusDataTableInvoiceIdColumnSettingViaKey = () => {
            const setting = getSettingViaKey(INVOICE_COLUMN_DATATABLE_SETTING_KEY);

            if (setting) {
                return setting.value;
            }
            return DEFAULT_INVOICE_ID_SHOW_BOTH;
        };
        
        const setUserSettingInvoiceId = (newValue) => {
            const setting = getSettingViaKey(INVOICE_COLUMN_DATATABLE_SETTING_KEY)
            setting.value = newValue;
        }

        const getALExpirationTime = () => {
            const expirationData = getSettingViaKey('nimbus.session-expiry-duration');
            if (expirationData) {
                return parseInt(expirationData.value);
            }

            return getExpiryTime();
        };

        const getALWarningTime = () => {
            const expirationData = getSettingViaKey('nimbus.idle-warning-duration');
            if (expirationData) {
                return parseInt(expirationData.value);
            }

            return getWarningTime();
        };

        /**
         * Function to clear the data upon logout
         */
        const clearUserData = () => {
            userData.value = {};
            userTimezone.value = {};
            userSettings.value = {};
            latestTokenRequest.value = {};
        };
        

        const getCurrency = () => {
            currencySettings.value = getCurrencySetting()
        }

        return {
            /**
             * State
             */
            userData,
            userTimezone,
            userSettings,
            latestTokenRequest,
            currencySettings,

            /**
             * Getters
             */
            getUserTimezoneDisplay,
            getUserDataEmailDomain,
            isEmersionUser,
            isBankAccountTypeRequired,
            isEup,
            DEFAULT_INVOICE_ID_SHOW_BOTH,
            INVOICE_ID_SHOW_ORIGINAL_INVOICE_ID,
            accountId,

            /**
             * Actions
             */
            setLatestTokenRequest,
            setUserAuthenticatedDetails,
            setUserSettingInvoiceId,
            getUserTimezone,
            getUserAccountSettings,
            getSettingViaKey,
            clearUserData,
            getALExpirationTime,
            getALWarningTime,
            getCurrency,
            getNimbusDataTableInvoiceIdColumnSettingViaKey,
        };
    },
    {
        persist: true,
    }
);
